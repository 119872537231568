import React from 'react'

export default () => {
  return (
    <ul style={{ padding: '20px' }}>
      <li>
        Semaglutide Prescribing Information,,{' '}
        <a href="https://www.accessdata.fda.gov/drugsatfda_docs/label/2020/209637s003lbl.pdf">click here</a>
      </li>
      <li>
        Tirzepatide Prescribing Information,{' '}
        <a href="https://www.accessdata.fda.gov/drugsatfda_docs/label/2022/215866s000lbl.pdf">click here</a>
      </li>
    </ul>
  )
}
